<template>
  <a-modal
    :keyboard="false"
    :maskClosable="false"
    :visible="visible"
    :title="$t('核算维度')"
    @cancel="handleDimensionCancel"
    @ok="handleDimensionOk"
  >
    <a-form-model>
      <a-form-model-item
        v-for="(item, index) in list"
        :key="index"
        :label="item.accountingDimensionName"
      >
        <CommonQuerySelect
          :init="true"
          api="getCommonList"
          :code.sync="item.accountingDimensionValueCode"
          :params="{
            businessUnitCode: businessUnitCode,
            tableName: item.accountingDimensionSourceTable,
          }"
          @change="
            (data) => {
              changeSelect(data, item)
            }
          "
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  props: {
    businessUnitCode: {},
    visible: {
      type: Boolean,
    },
    list: {
      type: Array,
    },
  },
  methods: {
    handleDimensionCancel() {
      this.$emit('update:visible', false)
    },
    handleDimensionOk() {
      this.handleDimensionCancel()
      this.$emit('ok')
    },
    changeSelect(data, item) {
      item.accountingDimensionValueCode = data.code
      item.accountingDimensionValueName = data.name
    },
  },
}
</script>
